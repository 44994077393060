import React, { useEffect, useState } from "react";
import "./App.css";
import Mine from "./Components/Mine";
import Error from "./Components/Error";
import axios from "axios";
import { SetMenuCategory } from "./store/MenuREducer";
import Loading from "./Components/Loading";
import {
  AllData,
  SetCoupons,
  SetLoading3,
  SetLoading2,
  SetLoading1,
  SetResteurantCode,
} from "./store/UserReducer";
import { setDelivery, SetCity } from "./store/OrderReducer";
import { useDispatch, useSelector } from "react-redux";
import { useOpenCheck } from "./Functions/useOpenCheck";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
function App() {
  const URL = useSelector((e) => e.UserReducer.url);
  const LOADING1 = useSelector((e) => e.UserReducer.loading1);
  const LOADING2 = useSelector((e) => e.UserReducer.loading2);
  const LOADING3 = useSelector((e) => e.UserReducer.loading3);
  const IsOpen = useOpenCheck();
  useEffect(() => {
    getAllMenu();
    getAllTexts();
    getDeliveryPlaces();
  }, [IsOpen]);
  const dispatch = useDispatch();
  const getAllMenu = async () => {
    await axios
      .get(`${URL}/get_menu.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //  console.log(response.data);
        if (
          SetMenuCategory(response.data.menu, dispatch) &&
          SetCoupons(response.data.coupons, dispatch)
        ) {
          SetLoading1(true, dispatch);
        }
      })

      .catch((err) => {
        console.log(err.response);
      });
  };
  const getAllTexts = async () => {
    await axios
      .get(`${URL}/get_website_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response);

        if (
          AllData(response.data, dispatch) &&
          IsOpen !== null &&
          SetResteurantCode(response.data.restaurant_info.code, dispatch)
        ) {
         
          SetLoading2(true, dispatch);
          console.log('JAAAAAAAAAAAAAAAAA');
        }
        console.log('JAAAAAAAAAAAAAAAAA');
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const [DeliveryPleases, setDeliveryPleases] = useState(null);
  const [DeliveryPleases2, setDeliveryPleases2] = useState(null);
  const getDeliveryPlaces = async () => {
    await axios
      .get(`${URL}/get_delivery_places.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // console.log(response.data);
        let array = {};
        let array2 = [];
        if (setDelivery(response.data, dispatch)) {
          Object.keys(response.data).map((element) => {
            if (response.data[element].is_place === false) {
              array[element] = response.data[element];
              array2.push(element);
            }
          });
          console.log(array);
          setDeliveryPleases(array);
          setDeliveryPleases2(array2);
          console.log(response.data);
          SetLoading3(true, dispatch);
        }
      })

      .catch((err) => {
        console.log(err.response);
      });
  };

  const CheckAppRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (DeliveryPleases2 !== null) {
          console.log(DeliveryPleases[props.match.params.id]);
          if (DeliveryPleases[props.match.params.id] !==undefined)  {
            if (SetCity(DeliveryPleases[props.match.params.id], dispatch)) {
              return <Mine {...props} />;
            }
          } else {
            window.location.href = "https://feedweb.pl/";
          }
        }
      }}
    />
  );
  const CheckAppRoute2 = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (DeliveryPleases !== null) {
          console.log(DeliveryPleases[props.match.params.id]);
          if (DeliveryPleases[props.match.params.id] !==undefined) {
            if (SetCity(props.match.params.id, dispatch)) {
              return <Mine {...props} />;
            }
          } else {
            window.location.href = "https://feedweb.pl/";
          }
        }
      }}
    />
  );
  if (
    LOADING1 === true &&
    LOADING2 === true &&
    LOADING3 === true &&
    IsOpen !== null
  ) {
    return (
      <Router>
        <Switch>
          <CheckAppRoute path="/:id" exact component={Mine} />
          <CheckAppRoute2 path="/" component={Error} />
        </Switch>
      </Router>
    );
  } else {
    return <Loading />;
  }
}

export default App;
