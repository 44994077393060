import React from "react";
import ItemWithOptions from "./ItemWithOptions";
import ItemNoOptions from "./ItemNoOptions";

export default function Category({ category, additions, addNoAdditions, key }) {
  return (
    <>
      <div className="row px-md-4 px-0 py-4 my-4 " id={category.id}>
        <div className="col-11 px-0 mb-2 px-md-0 text-right">
          {" "}
          <h1 className="Bold upper">{category.name}</h1>
        </div>

        {category.meal_bases.map((element) => {
          if (element.meal_options.length === 1 && element.is_active === true) {
            return (
              <ItemNoOptions
                key={element.id}
                meal={element}
                addition={category.addition_categories}
                func={additions}
                addNoAdditions={addNoAdditions}
              />
            );
          } else if (
            element.meal_options.length > 1 &&
            element.is_active === true
          ) {
            return (
              <ItemWithOptions
                key={element.id}
                meal={element}
                addition={category.addition_categories}
                func={additions}
                addNoAdditions={addNoAdditions}
              />
            );
          }
        })}
      </div>
    </>
  );
}
