export default function hexatorgb(hex, opacity) {
  //console.log(hex);
  hex = hex.toString();
  //console.log(hex);
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16);
  //console.log(hex.substring(0, 2));
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  let result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
  return result;
}
