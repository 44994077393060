export const ALL_DATA = "ALL_DATA";
export const SET_COUPNS = "SET_COUPNS";
export const SET_ALERT = "SET_ALERT";
export const SET_MODE = "SET_MODE";
export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_LOADING1 = "SET_LOADING1";
export const SET_LOADING2 = "SET_LOADING2";
export const SET_LOADING3 = "SET_LOADING3";
export const SET_RESTEURANTCODE = "SET_RESTEURANTCODE";
const initialState = {
  allData: {},
  resturantCode: null,
  coupons: {},
  alert: null,
  url: "https://resmanager.feedweb.pl",
  loading1: false,
  loading2: false,
  loading3: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ALL_DATA": {
      return {
        ...state,
        allData: action.payload,
      };
    }
    case "SET_COUPNS": {
      return {
        ...state,
        coupons: action.payload,
      };
    }
    case "SET_ALERT": {
      return {
        ...state,
        alert: action.payload,
      };
    }
    case "SET_MODE": {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case "SET_IS_OPEN": {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    case "SET_LOADING1": {
      return {
        ...state,
        loading1: action.payload,
      };
    }
    case "SET_LOADING2": {
      return {
        ...state,
        loading2: action.payload,
      };
    }
    case "SET_LOADING3": {
      return {
        ...state,
        loading3: action.payload,
      };
    }
    case "SET_RESTEURANTCODE": {
      return {
        ...state,
        resturantCode: action.payload,
      };
    }
    default: {
      //   console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};

export const AllData = (allData, dispatch) => {
  if (dispatch({ type: ALL_DATA, payload: allData })) {
    return true;
  }
};
export const SetCoupons = (coupons, dispatch) => {
  if (dispatch({ type: SET_COUPNS, payload: coupons })) {
    return true;
  }
};
export const SetAlert = (alert, dispatch) => {
  if (dispatch({ type: SET_ALERT, payload: alert })) {
    return true;
  }
};
export const SetMode = (mode, dispatch) => {
  if (dispatch({ type: SET_MODE, payload: mode })) {
    return true;
  }
};
export const SetIsOpen = (isOpen, dispatch) => {
  if (dispatch({ type: SET_IS_OPEN, payload: isOpen })) {
    return true;
  }
};
export const SetLoading1 = (loading1, dispatch) => {
  if (dispatch({ type: SET_LOADING1, payload: loading1 })) {
    return true;
  }
};
export const SetLoading2 = (loading2, dispatch) => {
  if (dispatch({ type: SET_LOADING2, payload: loading2 })) {
    return true;
  }
};
export const SetLoading3 = (loading3, dispatch) => {
  if (dispatch({ type: SET_LOADING3, payload: loading3 })) {
    return true;
  }
};
export const SetResteurantCode = (resturantCode, dispatch) => {
  if (dispatch({ type: SET_RESTEURANTCODE, payload: resturantCode })) {
    return true;
  }
};
