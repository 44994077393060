import React, { useState, useEffect } from "react";
import "../CSS/ItemWithOptions.css";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SetAlert } from "../store/UserReducer";
import ReactHtmlParser from "react-html-parser";
export default function ItemWithOptions({
  meal,
  addition,
  func,
  addNoAdditions,
}) {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const URL = useSelector((e) => e.UserReducer.url);
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Base_font_color, setBase_font_color] = useState("");
  const [Main_color, setMain_color] = useState("");
  const [Meal_sizes_button, setMeal_sizes_button] = useState("");
  const [Added_to_order_communicate, setAdded_to_order_communicate] = useState(
    ""
  );
  const [Kcal_text, setKcal_text] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
      setBase_font_color(UserInfo.colors.base_font_color);
      setMain_color(UserInfo.colors.main_color);
    }
    if (UserInfo.constant_texts !== undefined) {
      setMeal_sizes_button(UserInfo.editable_texts.meal_sizes_button);
      setAdded_to_order_communicate(
        UserInfo.constant_texts.added_to_order_communicate
      );
      setKcal_text(UserInfo.constant_texts.kcal_text);
      setOrder_currency(UserInfo.constant_texts.order_currency);
    }
  }, [UserInfo]);
  const [VisibilityConfirm, setVisibilityConfirm] = useState("hidden");
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <button
        type="button"
        style={{
          border: "none",
          background: meal.is_promoted
            ? `${Additions_font_color}`
            : `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
          color: meal.is_promoted
            ? `${Additions_stop_color}`
            : `${Additions_font_color}`,
        }}
        className="btn add-regular Bold f-size-button"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const add = (elem) => {
    let order = {};
    order = {
      name: meal.name,
      idMeal: meal.id,
      optName: elem.name,
      price: elem.price,
      idOption: elem.id,
    };

    if (addition.length === 0) {
      if (addNoAdditions(order, addition)) {
        setVisibilityConfirm("visible");
        setTimeout(function () {
          setVisibilityConfirm("hidden");
        }, 1200);
      }
    } else if (addition.length > 0) {
      func(order, addition);
      window.scrollTo(0, 0);
    }
  };
  return (
    <>
      <Accordion defaultActiveKey="1" className="w-100 my-0">
        <div className="col-11 px-0 my-2 d-flex align-items-center">
          <div
            className={
              meal.photo_url !== "" ? "row ItemBox w-100" : "row ItemBox2 w-100"
            }
            style={{
              background: meal.is_promoted
                ? `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`
                : `#ffffff`,
              color: meal.is_promoted
                ? `${Additions_font_color}`
                : `${Base_font_color}`,
            }}
          >
            {meal.photo_url !== "" ? (
              <div
                className="col-2 px-0 img-item"
                style={{
                  backgroundImage: `url(  ${URL + meal.photo_url}  )`,
                }}
              ></div>
            ) : (
              ""
            )}

            <div
              className={
                meal.photo_url !== ""
                  ? "col-7 pr-0 pl-md-4 pl-1 d-flex align-items-center mx-0 my-0"
                  : "col-9 pr-0 pl-md-4 pl-1 d-flex align-items-center mx-0 my-0"
              }
            >
              <span className="my-lg-0 pr-2">
                <p className="Bold  my-0 f-size-title text-break mb-1">
                  {meal.name}{" "}
                </p>
                <p className="Lite my-0 f-size-disc text-break">
                  {" "}
                  {meal.ingredients}
                </p>
              </span>
            </div>
            <div className=" col-3 pl-0 pr-2 mx-0 my-2">
              <div className="col-12 px-0 text-right d-flex align-items-center justify-content-end h1001 position-relative">
                {meal.icons.length > 0 ? (
                  meal.icons.map((element) => (
                    <>
                      <img
                        key={element.id}
                        src={URL + element.icon_url}
                        id="a"
                      />
                    </>
                  ))
                ) : (
                  <p className=" Lite f-size-disc my-0">
                    {ReactHtmlParser("&nbsp;")}
                  </p>
                )}
              </div>
              <div className="col-12 px-0 text-right d-flex align-items-center justify-content-end  h1002 position-relative">
                <p className=" Lite f-size-disc my-0">
                  {ReactHtmlParser("&nbsp;")}
                </p>
              </div>
              <div className="col-12 px-0 text-right d-flex align-items-end justify-content-end h1003 position-relative">
                <CustomToggle eventKey="0"> {Meal_sizes_button}</CustomToggle>
              </div>
            </div>
            <div
              className={
                "confirmation2 text-center  d-flex justify-content-center align-items-center " +
                VisibilityConfirm
              }
            >
              <p className="mb-0 Bold text-white h3 h3-custom">
                {Added_to_order_communicate}
              </p>
            </div>
          </div>
        </div>
        <div className="col-11 px-0  d-flex align-items-center">
          <Card className="w-100 border-0 px-0">
            <Accordion.Collapse eventKey="0">
              <Card.Body className="w-100 border-0 px-0">
                {meal.meal_options.map((element) => {
                  if (element.is_active) {
                    return (
                      <div
                        key={element.id}
                        className="col-12 px-0 py-3 d-flex align-items-center border-bottom"
                      >
                        <div className=" col-lg-6 col-5 px-0 text-left Bold f-size-title pl-3">
                          {element.name}
                        </div>
                        <div className=" col-lg-3 col-4 pl-5 mx-0 my-0 text-right">
                          <p className="my-0 ml-sm-5 Lite f-size-disc">
                            {" "}
                            {element.kcal}
                            {element.kcal === "" || element.kcal === null
                              ? ReactHtmlParser("&nbsp;")
                              : Kcal_text}
                          </p>
                          <p className="my-0 ml-sm-5 Lite f-size-disc">
                            {" "}
                            {element.size}
                          </p>
                        </div>
                        <div className=" col-3  pl-0 pr-2 mx-0 ">
                          <div className="col-12 pl-sm-4 pl-0 pr-0  d-flex align-items-center justify-content-end h1003 position-relative">
                            <button
                              type="button"
                              key={element.id}
                              style={{
                                background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
                                color: `${Additions_font_color}`,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                              className="btn add-regular Bold f-size-button "
                              onClick={() => add(element)}
                            >
                              {element.price.toFixed(2)}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </>
  );
}
