import React, { useState, useEffect } from "react";
import "../CSS/Footer.css";
import { useSelector } from "react-redux";
import round from "../Functions/round";
import { Link, animateScroll as scroll } from "react-scroll";
import HeaderIconSelector from "./HeaderIconSelector";
export default function Footer() {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [LogoURL, setLogoURL] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyCity, setCompanyCity] = useState("");
  const [CompanyPhoneNr, setCompanyPhoneNr] = useState("");
  const [CompanyNIP, setCompanyNIP] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyStreet, setCompanyStreet] = useState("");
  const [CompanyOpenText, setCompanyOpen] = useState("");
  const [CompanyOpenHours, setCompanyOpenHours] = useState([]);
  const [CompanyCloseHours, setCompanyCloseHours] = useState([]);
  const [DayNumber, setDayNumber] = useState();
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Facebook_link, setFacebook_link] = useState("");
  const [Instagram_link, setInstagram_link] = useState("");
  const [Restaurant_nip, setRestaurant_nip] = useState("");
  const [Visit_socials, setVisit_socials] = useState("");
  const [Site_regulations_link, setSite_regulations_link] = useState("");
  const [Site_regulations, setSite_regulations] = useState("");
  const [Site_proviacy_policy_link, setSite_proviacy_policy_link] = useState(
    ""
  );
  const [Site_privacy_policy, setSite_privacy_policy] = useState("");
  const [Dotpay_addition, setDotpay_addition] = useState("");
  const [Feedweb_addition, setFeedweb_addition] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [Basket_text, setBasket_text] = useState("");
  const [DayArray, setDayArray] = useState("");
  useEffect(() => {
    if (UserInfo.constant_texts !== undefined) {
      setRestaurant_nip(UserInfo.constant_texts.restaurant_nip);
      setSite_regulations_link(UserInfo.constant_texts.site_regulations_link);
      setSite_regulations(UserInfo.constant_texts.site_regulations);
      setSite_proviacy_policy_link(
        UserInfo.constant_texts.site_proviacy_policy_link
      );
      setSite_privacy_policy(UserInfo.constant_texts.site_privacy_policy);
      setDotpay_addition(UserInfo.constant_texts.dotpay_addition);
      setFeedweb_addition(UserInfo.constant_texts.feedweb_addition);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setBasket_text(UserInfo.constant_texts.basket_text);
    }
    if (UserInfo.editable_texts !== undefined) {
      setCompanyName(UserInfo.editable_texts.restaurant_name);
      setCompanyCity(UserInfo.editable_texts.restaurant_city);
      setCompanyPhoneNr(UserInfo.editable_texts.restaurant_phone);
      setCompanyNIP(UserInfo.editable_texts.restaurant_nip);
      setCompanyEmail(UserInfo.editable_texts.restaurant_email);
      setCompanyStreet(UserInfo.editable_texts.restaurant_street);
      setCompanyOpen(UserInfo.editable_texts.restaurant_open_hours);
      setFacebook_link(UserInfo.editable_texts.facebook_link);
      setInstagram_link(UserInfo.editable_texts.instagram_link);
      setVisit_socials(UserInfo.editable_texts.visit_socials);
    }
    if (UserInfo.img !== undefined) {
      setLogoURL(UserInfo.img.logo);
    }
    if (UserInfo.opening_hour !== undefined) {
      setCompanyOpenHours(UserInfo.opening_hour);
      let d = new Date();
      let n = d.getDay();
      setDayNumber(n);
    }
    if (UserInfo.closing_hour !== undefined) {
      setCompanyCloseHours(UserInfo.closing_hour);
    }
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }
    if (UserInfo.week_day_name !== undefined) {
      setDayArray(UserInfo.week_day_name);
    }
  }, [UserInfo]);
  const Order = useSelector((e) => e.OrderReducer.order);
  const City = useSelector((e) => e.OrderReducer.city);
  const OffRedux = useSelector((e) => e.OrderReducer.off);
  const Ammount = useSelector((e) => e.OrderReducer.ammount);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);

  return (
    <div className="container-fluid ">
      <div className="container footer px-0 ">
        <div className="px-4 mt-5 mb-2 d-md-none d-block">
          <img src={LogoURL} id="logo-footer" alt="" className=" " />
        </div>
        <div className="row pt-3 pt-md-5 pb-md-5 pb-3 px-4">
          <div className="col-md-3 mb-md-3 mb-1 mb-md-0 px-0 h-auto d-md-block d-none">
            <img src={LogoURL} id="logo-footer" alt="" className=" " />
          </div>

          <div className="col-md-9 px-0">
            <h3 className="Bold mb-md-2 mb-3  footer-font2"> {CompanyName}</h3>
            <div className="row ">
              <div className="col-lg-4 col-md-6 mb-md-4 mb-2 px-0">
                <p className="mb-1 Bold footer-font1">
                  {DayArray[DayNumber]}:{" "}
                  {CompanyOpenHours[DayNumber] !== "-" &&
                  CompanyCloseHours[DayNumber] !== "-"
                    ? `${CompanyOpenHours[DayNumber]} - ${CompanyCloseHours[DayNumber]}`
                    : "Nieczynne"}
                </p>
                <p className="mb-1 Lite footer-font1"> {CompanyStreet}</p>
                <p className="mb-1 Lite footer-font1">{CompanyCity}</p>
              </div>
              <div className="col-lg-4 col-md-6 mb-md-4 mb-2 px-0">
                <p className="mb-1 Bold footer-font1">{CompanyPhoneNr}</p>
                <p className="mb-1 Lite footer-font1"> {CompanyEmail}</p>
                <p className="mb-1 Lite footer-font1">
                  {" "}
                  {Restaurant_nip} {CompanyNIP}
                </p>
              </div>
              <div className="col-md-4 mb-md-4 mb-2 px-0 Lite  ">
                <p className="mb-1 Lite footer-font1"> {Visit_socials}</p>
                {Facebook_link !== "" ? (
                  <a href={Facebook_link} target="_blank">
                    <HeaderIconSelector icon="fb" />
                  </a>
                ) : (
                  ""
                )}
                {Instagram_link !== "" ? (
                  <a href={Instagram_link} target="_blank">
                    {" "}
                    <HeaderIconSelector icon="ig" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row pl-md-5 pl-0">
          <div className="col-md-8 pl-md-5 pl-4 ">
            <div className="row px-0 ">
              <div className="col-md-4  px-0 mb-md-0 mb-3">
                <a href={Site_regulations_link} id="ah">
                  {" "}
                  <p className="mb-1 Lite size-1">
                    <u> {Site_regulations}</u>{" "}
                  </p>
                </a>
                <a href={Site_proviacy_policy_link} id="ah">
                  {" "}
                  <p className="mb-1 Lite size-1">
                    <u> {Site_privacy_policy}</u>
                  </p>
                </a>
              </div>
              <div className="col-md-4  px-0 mb-3">
                <p className=" size-1 Lite  my-0">{Dotpay_addition}</p>
              </div>
              <div className="col-4 px-0 "></div>
            </div>
          </div>
          <div
            className="col-md-4  d-flex py-0 align-items-center fw-footer"
            style={{
              background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
              color: `${Additions_font_color}`,
              zIndex:'1000'
            }}
          >
            <svg width="80" height="80" viewBox="0 0 80 80" className="mr-3">
              <defs></defs>
              <g transform="translate(-579 -476)">
                <g transform="translate(583 485)">
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M0,84.338V74.289H7.9v1.156H1.247v2.949H5.27V79.55H1.247v4.788Z"
                    transform="translate(0 -22.976)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M10.608,85.357V77.584H17.9v1.138H11.828V80.66h3.987v1.12H11.828v2.458h6.409v1.12Z"
                    transform="translate(-3.281 -23.995)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M24.156,85.357V77.584h7.291v1.138H25.375V80.66h3.988v1.12H25.375v2.458h6.409v1.12Z"
                    transform="translate(-7.471 -23.995)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M41.179,77.584a4.314,4.314,0,0,1,3.067,1.088A3.69,3.69,0,0,1,45.412,81.5a3.634,3.634,0,0,1-1.142,2.808,4.386,4.386,0,0,1-3.09,1.051h-3.45V77.584ZM41.2,78.7H38.949v5.534H41.2a3.058,3.058,0,0,0,2.176-.742,2.909,2.909,0,0,0-.028-4.019A3,3,0,0,0,41.2,78.7Z"
                    transform="translate(-11.669 -23.995)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M61.828,84.338,58.551,74.289H59.78l2.494,7.755,2.558-7.755h1.12L68.5,82.026,71,74.289H72.2L68.928,84.338h-.91l-2.649-8.092-2.621,8.092Z"
                    transform="translate(-18.109 -22.976)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M79.464,85.357V77.584h7.291v1.138H80.684V80.66h3.987v1.12H80.684v2.458h6.409v1.12Z"
                    transform="translate(-24.577 -23.995)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M93.038,85.365V77.61h4.779a2.827,2.827,0,0,1,1.793.487,1.727,1.727,0,0,1,.61,1.434,1.807,1.807,0,0,1-.191.842,1.523,1.523,0,0,1-.555.6,2.644,2.644,0,0,1,.974.888,2.176,2.176,0,0,1,.327,1.178,2.134,2.134,0,0,1-.723,1.707,2.963,2.963,0,0,1-2,.624ZM97.78,78.73H94.258v1.83H97.8a1.407,1.407,0,0,0,.879-.232.822.822,0,0,0,.3-.687.808.808,0,0,0-.3-.682A1.46,1.46,0,0,0,97.78,78.73Zm.41,2.967H94.258v2.549H98.19a1.369,1.369,0,0,0,.988-.336,1.253,1.253,0,0,0,.35-.947,1.225,1.225,0,0,0-.35-.933A1.382,1.382,0,0,0,98.19,81.7Z"
                    transform="translate(-28.775 -24.003)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M28.488,10.125,25.953,7.259a22.217,22.217,0,0,0-6.168,15.4c0,.477.014.946.041,1.416v.007h3.813v-.007c-.035-.47-.055-.939-.055-1.416A18.435,18.435,0,0,1,28.488,10.125Zm.318-5.388L31.341,7.6a18.341,18.341,0,0,1,5.084-2.569V1.07A22.141,22.141,0,0,0,28.806,4.738Z"
                    transform="translate(-6.119 -0.331)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M64.431,34.39a22.338,22.338,0,0,1-44.586,0h3.813A18.576,18.576,0,0,0,40.242,51.409V38.2h3.8V51.409A18.575,18.575,0,0,0,60.618,34.39Z"
                    transform="translate(-6.138 -10.636)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M79.281,24.3c0,.477-.014.946-.041,1.416v.007H75.427v-.007c.035-.47.055-.939.055-1.416A18.494,18.494,0,0,0,67.725,9.243L70.26,6.37a22.5,22.5,0,0,1,2.853,2.521A22.277,22.277,0,0,1,79.281,24.3Z"
                    transform="translate(-20.946 -1.97)"
                  />
                  <path
                    style={{ fill: `${Additions_font_color}` }}
                    d="M62.05,7.273,64.585,4.4A22.235,22.235,0,0,0,51.274,0c-.642,0-1.271.028-1.9.083V23.747h3.8V12.613h7.515v-3.8H53.174V3.9A18.336,18.336,0,0,1,62.05,7.273Z"
                    transform="translate(-15.271 0)"
                  />
                </g>
                <rect
                  className="c"
                  width="80"
                  height="80"
                  transform="translate(579 476)"
                />
              </g>
            </svg>
            <p
              className=" size-1 Lite c-white my-0"
              style={{ color: `${Additions_font_color}` }}
            >
              {Feedweb_addition}
            </p>
          </div>
        </div>
        <svg width="145" height="49" viewBox="0 0 145 49" className="cart" 
        style={{
          zIndex:'1'
        }}>
          <defs>
            <style>{`.cc,.dd{fill:${Additions_stop_color};}.cc{font-size:16px;font-family:Roboto-Bold, Roboto;font-weight:700;}.dd{font-size:12px;font-family:Roboto-Light, Roboto;font-weight:300;letter-spacing:-0.025em;}}`}</style>
            <linearGradient
              id="a"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0%" />
              <stop offset="100%" />
            </linearGradient>
          </defs>
          <Link
            activeClass="active"
            className="my-1"
            to="OrderList"
            spy={true}
            smooth={true}
            offset={-120}
            duration={300}
            style={{ cursor: "pointer" }}
          >
            <g transform="translate(-346 -826)">
              <rect
                style={{ fill: `${Additions_start_color}` }}
                width="145"
                height="49"
                rx="10"
                transform="translate(346 826)"
              />
              <rect
                style={{ fill: `${Additions_font_color}` }}
                width="139"
                height="43"
                rx="8"
                transform="translate(349 829)"
              />
              <text class="cc" transform="translate(404 862)">
                <tspan x="0" y="0">
                  {City !== undefined &&
                  City !== null &&
                  Object.keys(Order).length > 0
                    ? Ammount - OffRedux > FreeAbove
                      ? round(Ammount - OffRedux).toFixed(2)
                      : round(Ammount + City.delivery_price - OffRedux).toFixed(
                          2
                        )
                    : round(Ammount - OffRedux).toFixed(2)}{" "}
                  {Order_currency}
                </tspan>
              </text>
              <text class="dd" transform="translate(404 846)">
                <tspan x="0" y="0">
                  {Basket_text} ({Object.keys(Order).length})
                </tspan>
              </text>
              <g
                transform="translate(365.149 835.149)"
                style={{ fill: `${Additions_stop_color}` }}
              >
                <path
                  class="e"
                  d="M7.713,25.71a2.571,2.571,0,1,1,2.571-2.571A2.574,2.574,0,0,1,7.713,25.71ZM20.567,25.7a2.558,2.558,0,1,1,2.571-2.558A2.568,2.568,0,0,1,20.567,25.7Zm2.571-6.415H7.712a2.542,2.542,0,0,1-2.2-1.255,2.569,2.569,0,0,1-.045-2.563L7.2,12.328,2.571,2.572H0V0H4.2L5.412,2.572H24.436a1.281,1.281,0,0,1,1.119,1.9l-4.6,8.343a2.562,2.562,0,0,1-2.25,1.324H9.127L7.712,16.711H23.138v2.569ZM6.632,5.142h0l3.046,6.427H18.7l3.548-6.427Z"
                  transform="translate(1.286 2.57)"
                />
              </g>
            </g>
          </Link>
        </svg>
      </div>
    </div>
  );
}
