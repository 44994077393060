import React from "react";
import "../CSS/Display.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MineDisplay from "./MineDisplay";
import Success from "./Success";
import Error from "./Error";
//import Status from "./Status";

export default function Display({ height }) {
  return (
    <div
      className="container display  px-0 position-relative "
      style={{ marginTop: height, minHeight: window.innerHeight - height }}
    >
      <Router>
        <Switch>
          <Route path="/" exact render={() => <MineDisplay test={height} />} />
          <Route path="/success" render={() => <Success test={height} />} />
          <Route path="/error/:bool" render={() => <Error test={height} />} />
        </Switch>
      </Router>
    </div>
  );
}
