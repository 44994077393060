import React, { useState, useEffect } from "react";
import { Spring } from "react-spring/renderprops";
import { useSelector } from "react-redux";
import AdditionsList from "./AdditionsList";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useOpenCheck } from "../Functions/useOpenCheck";
import round from "../Functions/round";
import "../CSS/TakeInfo.css";
function SubmitDisplay({
  json,
  description,
  name,
  surname,
  additions_font_color,
  additions_start_color,
  additions_stop_color,
  height,
  set,
  history,
}) {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const Order = useSelector((e) => e.OrderReducer.order);
  //const City = useSelector((e) => e.OrderReducer.city);
  const Off = useSelector((e) => e.OrderReducer.off);
  const URL = useSelector((e) => e.UserReducer.url);
  const [Disabled, setDisabled] = useState(false);
  const [OnlinePay, setOnlinePay] = useState("");
  const [OfflinePay, setOfflinePay] = useState("");
  const IsOpen = useOpenCheck();
  const [Order_summary, setOrder_summary] = useState("");
  const [Discount_text, setDiscount_text] = useState("");
  const [Amount_text, setAmount_text] = useState("");
  const [Delivery_address_header, setDelivery_address_header] = useState("");
  const [Edit_order_button, setEdit_order_button] = useState("");
  const [Wait_text, setWait_text] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [Enclosure, setEnclosure] = useState('')
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setOrder_summary(UserInfo.editable_texts.order_summary);
      setDelivery_address_header(
        UserInfo.editable_texts.delivery_address_header
      );
    
    }
    if (UserInfo.constant_texts !== undefined) {
      setOnlinePay(UserInfo.constant_texts.pay_online_button);
      setOfflinePay(UserInfo.constant_texts.pay_offline_button);
      setDiscount_text(UserInfo.constant_texts.discount_text);
      setAmount_text(UserInfo.constant_texts.amount_text);
      setEdit_order_button(UserInfo.constant_texts.edit_order_button);
      setWait_text(UserInfo.constant_texts.wait_text);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setEnclosure(UserInfo.constant_texts.data_privacy_enclosure)
    }
  }, [UserInfo]);
  const RestaurantCode = useSelector((e) => e.UserReducer.resturantCode);

  const submitOnlinePay = async () => {
    console.log(IsOpen);
    if (IsOpen) {
      let sendJson = json;
      sendJson.payment_type = "1";
      sendJson.payment_status = "1";
      sendJson.progress = "1";
      await axios
        .post(
          `https://vps11835.mikrohost.pl/${RestaurantCode}/ordersp24`,
          sendJson,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(JSON.parse(Json));
          //console.log(Code);
          if (response.status === 200 && response.statusText === "OK" && JSON.parse(response.data).data.token!==undefined) {
            //document.getElementById("ToSubmit").submit(); 
            window.location.replace(`https://sandbox.przelewy24.pl/trnRequest/${JSON.parse(response.data).data.token}`);
              } else if (
              response.status === 400 &&
               response.data === "Restaurant not active"
              ) {
               history.push("/error/1");
             } else {
               history.push("/error/0");
            //console.log(response.data);
              }
        })
        .catch((err) => {
          console.log(err.response);
          if (
            err.response.status === 400 &&
            err.response.data === "Restaurant not active"
          ) {
            history.push("/error/1");
          } else {
            history.push("/error/0");
            //console.log(response.data);
          }
        });
    }
  };

  const submitOfflinePay = async () => {
    if (IsOpen) {
      let sendJson = json;
      sendJson.payment_type = "2";
      sendJson.payment_status = "2";
      sendJson.progress = "2";
      await axios
        .post(
          `https://vps11835.mikrohost.pl/${RestaurantCode}/ordersp24`,
          sendJson,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(JSON.parse(Json));
          //console.log(Code);
          if (response.status === 201 && response.statusText === "Created") {
            history.push("/success");
          } else if (
            response.status === 400 &&
            response.data === "Restaurant not active"
          ) {
            history.push("/error/1");
          } else {
            history.push("/error/0");
            //console.log(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (
            err.response.status === 400 &&
            err.response.data === "Restaurant not active"
          ) {
            history.push("/error/1");
          } else {
            history.push("/error/0");
            //console.log(response.data);
          }
        });
    }
  };
  const editOrder = () => {
    set("Mine");
  };
  if (
    json !== null &&
    description !== null &&
    name !== null &&
    surname !== null
  )
    return (
      <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
        {(props) => (
          <div
            className="mt-md-5 pt-5 px-md-5 pt-0 pb-5 cart-hidden2"
            style={{
              props,

              background: `url(${URL}/img/restaurant/background_accept_order.svg),transparent linear-gradient(180deg, ${additions_start_color} 0%, ${additions_stop_color} 100%) 0% 0% no-repeat  padding-box`,

              color: `${additions_font_color}`,
              minHeight: window.innerHeight - height,
            }}
          >
            <div className="row px-3 py-2">
              <div className="col-10 px-0">
                <p className="h1 Bold upper my-sm-4 Order-font1">
                  {Order_summary}
                </p>
              </div>

              {Object.keys(Order).map((element) => (
                <div className="col-12 px-0 py-2">
                  <div className="row">
                    <div className="col-lg-5 col-sm-9 col-8 Lite px-0">
                      <h5 className="upper mb-0 Order-font2">
                        {" "}
                        {Order[element].name}{" "}
                        {Order[element].optName === ""
                          ? ""
                          : "- " + Order[element].optName}
                      </h5>
                    </div>
                    <div className="col-sm-3 col-4 Lite px-0 Order-font2">
                      {" "}
                      {round(Order[element].localAmmount).toFixed(2)}{" "}
                      {Order_currency}
                    </div>

                    <div className="col-lg-5 px-0 ">
                      <AdditionsList additionList={Order[element].additions} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {Off > 0 ? (
              <div className="row px-3 pt-3 pt-2">
                <div className="col-12 px-0 py-1">
                  <div className="row">
                    <div className="col-lg-5 col-sm-9 col-8 Lite px-0">
                      <h5 className="upper mb-0">{Discount_text}</h5>
                    </div>
                    <div className="col-sm-3 col-4 Lite px-0">
                      {" "}
                      -{Off.toFixed(2)} {Order_currency}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row px-3 Bold pb-3">
              <div className="col-lg-5 col-sm-9 col-8 px-0  py-1">
                <p className="h4 upper">{Amount_text}</p>
              </div>
              <div className="col-sm-3 col-4 px-0 py-1">
                {" "}
                <p className="h4">
                  {json.to_pay} {Order_currency}
                </p>
              </div>
            </div>{" "}
            <div className="row px-3 pb-4">
              <div className="col-7 px-0 py-1">
                {" "}
                <p className="h5">{json.comment}</p>
              </div>
            </div>
            <div className="row px-3 py-0">
              <div className="col-10 px-0">
                <p className="h5 Lite py-2 mb-0 Order-font2">
                  {json.phone_number}
                </p>
                <p className="h5 Lite py-2 mb-0 Order-font2">{json.email}</p>
              </div>{" "}
            </div>
            <div className="row px-3 pt-3">
              <div className="col-10 px-0">
                <p
                  className="h5 Lite py-2 mb-0"
                  style={{
                    fontSize: "9px",
                  }}
                >
                  {Enclosure}
                </p>
              </div>
            </div>
            <div className="row px-3 py-0 mt-4">
              <div className="px-0 col-md-5 col-lg-3  mr-1 mb-md-3 pt-3">
                <button
                  type="button"
                  style={{
                    color: `${additions_font_color}`,
                    border: `1px solid ${additions_font_color}`,
                    backgroundColor: `rgba(255,255,255,0)`,
                  }}
                  className=" editButton Bold f-size py-2 upper Order-font2"
                  onClick={() => {
                    editOrder();
                    setDisabled(true);
                  }}
                  disabled={Disabled}
                >
                  {Edit_order_button}
                </button>
              </div>
            </div>
            <div className="row px-3 py-0">
              <div className="px-0 col-md-5 col-lg-3 mr-md-5 mr-0 mb-md-0 mt-3 mt-md-0 pt-4">
                <button
                  type="button"
                  style={{
                    background: `${additions_font_color}`,
                    color: `${additions_stop_color}`,
                  }}
                  className=" editButton Bold f-size py-2 upper Order-font2"
                  onClick={() => {
                    submitOnlinePay();
                    setDisabled(true);
                    setOnlinePay(Wait_text);
                  }}
                  disabled={Disabled}
                >
                  {OnlinePay}
                </button>{" "}
              </div>
              <div className="px-0 col-md-5 col-lg-3 mb-md-0 mb-3 pt-4">
                <button
                  type="button"
                  style={{
                    background: `${additions_font_color}`,
                    color: `${additions_stop_color}`,
                  }}
                  className=" editButton Bold f-size py-2 upper Order-font2"
                  onClick={() => {
                    submitOfflinePay();
                    setDisabled(true);
                    setOfflinePay(Wait_text);
                  }}
                  disabled={Disabled}
                >
                  {OfflinePay}
                </button>{" "}
              </div>
            </div>          
          </div>
        )}
      </Spring>
    );
}
export default withRouter(SubmitDisplay);
