import React, { useState, useEffect } from "react";
import { Spring } from "react-spring/renderprops";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
function Success({ test, history }) {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [
    Back_from_confirmation_button,
    setBack_from_confirmation_button,
  ] = useState("");
  const [Order_accepted_title, setOrder_accepted_title] = useState("");
  const [Online_order_accepted_text, setOnline_order_accepted_text] = useState(
    ""
  );
  const [After_payment_url, setAfter_payment_url] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }
    if (UserInfo.editable_texts !== undefined) {
      setOrder_accepted_title(UserInfo.editable_texts.order_accepted_title);
      setOnline_order_accepted_text(
        UserInfo.editable_texts.online_order_accepted_text
      );
    }
    if (UserInfo.constant_texts !== undefined) {
      setBack_from_confirmation_button(
        UserInfo.constant_texts.back_from_confirmation_button
      );
    }
    if (UserInfo.dotpay !== undefined) {
      setAfter_payment_url(UserInfo.dotpay.after_payment_url);
      // console.log(UserInfo.img.logo);
    }
  }, [UserInfo]);
  const goToMinePage = () => {
    window.location.href = After_payment_url;
  };
  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {(props) => (
        <div
          className="my-0 pt-0 pb-5 d-flex align-items-center"
          style={{
            props,
            background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
            color: `${Additions_font_color}`,
            minHeight: window.innerHeight - test,
          }}
        >
          <div className="row px-3 py-2 w-100">
            <div className="col-12 text-center  mt-5  mb-2">
              <p className="h1 Bold">{Order_accepted_title}</p>
            </div>
            <div className="col-sm-6 offset-sm-3 text-center">
              <p className="h6 Lite">{Online_order_accepted_text}</p>
            </div>
            <div className="col-sm-4 offset-sm-4 text-center mt-3  mb-2">
              <button
                type="button"
                style={{
                  color: `${Additions_stop_color}`,
                  backgroundColor: `${Additions_font_color}`,
                }}
                className=" editButton Bold upper py-2"
                onClick={() => {
                  goToMinePage();
                }}
              >
                {Back_from_confirmation_button}
              </button>{" "}
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
}
export default withRouter(Success);
