export default function round(value) {
  let amount_before_round = value;
  let amount_after_round = parseInt(amount_before_round * 100) / 100;
  //  console.log("c1 " + amount_before_round);
  //  console.log("c2 " + amount_after_round);
  if (amount_before_round - amount_after_round > 0.005) {
    // console.log(amount_before_round - amount_after_round);
    amount_after_round = amount_after_round + 0.011;
    // console.log(amount_after_round);
    amount_after_round = parseInt(amount_after_round * 100) / 100;
    // console.log(amount_after_round);

    // console.log("c4 " + amount_after_round);
  }
  return amount_after_round;
}
