import React, { useState, useEffect } from "react";
import "../CSS/TakeInfo.css";
import { useSelector, useDispatch } from "react-redux";
import hexatorgb from "../Functions/hexatorgb";
import { Form } from "react-bootstrap";
import { SetAlert } from "../store/UserReducer";
import {
  SetCity,
  SetStreet,
  SetBuildNr,
  SetName,
  SetSurame,
  SetPhone,
  SetEmail,
  SetDeliveryAmmount,
  SetFreeAbove,
} from "../store/OrderReducer";
export default function ClientInfo({
  color,
  email,
  street,
  bulidingNr,
  name,
  surname,
  phone,
}) {
  const dispatch = useDispatch();
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const Delivery = useSelector((a) => a.OrderReducer.delivery);
  const Street = useSelector((a) => a.OrderReducer.street);
  const BuildNr = useSelector((a) => a.OrderReducer.buildNr);
  const Name = useSelector((a) => a.OrderReducer.name);
  const Surname = useSelector((a) => a.OrderReducer.surname);
  const Phone = useSelector((a) => a.OrderReducer.phone);
  const Email = useSelector((a) => a.OrderReducer.email);
  const City = useSelector((a) => a.OrderReducer.city);
  const OffRedux = useSelector((e) => e.OrderReducer.off);
  const Ammount = useSelector((e) => e.OrderReducer.ammount);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);
  const [DeliveryCostIncluded, setDeliveryCostIncluded] = useState("");
  const [DeliveryRegionPrice, setDeliveryRegionPrice] = useState("");
  const [Color, setColor] = useState("");
  const [Delivery_city, setDelivery_city] = useState("");
  const [Delivery_client_contact, setDelivery_client_contact] = useState("");
  const [Delivery_address_header, setDelivery_address_header] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [Delivery_is_free, setDelivery_is_free] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setColor(hexatorgb(UserInfo.colors.additions_font_color, 15));
    }
    if (UserInfo.editable_texts !== undefined) {
      setDelivery_client_contact(
        UserInfo.editable_texts.delivery_client_contact
      );
      setDelivery_address_header(
        UserInfo.editable_texts.delivery_address_header
      );
    }
    if (UserInfo.constant_texts !== undefined) {
      setDeliveryCostIncluded(UserInfo.constant_texts.delivery_cost_included);
      setDeliveryRegionPrice(UserInfo.constant_texts.delivery_region_price);
      setDelivery_city(UserInfo.constant_texts.delivery_city);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setDelivery_is_free(UserInfo.constant_texts.delivery_is_free);
    }
  }, [UserInfo]);
  const CityValid = (event) => {
    if (Delivery[event.target.value] !== undefined) {
      SetCity(Delivery[event.target.value], dispatch);
      SetDeliveryAmmount(Delivery[event.target.value].delivery_price, dispatch);
      SetFreeAbove(Delivery[event.target.value].free_above, dispatch);
    } else {
      SetCity(null, dispatch);
      SetDeliveryAmmount(0, dispatch);
      SetFreeAbove(null, dispatch);
    }
  };
  const StreetValid = (event) => {
    SetStreet(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const BuildNrValid = (event) => {
    SetBuildNr(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const NameValid = (event) => {
    SetName(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const SurnameValid = (event) => {
    SetSurame(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const PhoneValid = (event) => {
    SetPhone(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const EmailValid = (event) => {
    SetEmail(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  return (
    <div className="col-12 px-md-5 px-4  pt-1 mt-md-0 mt-1 mb-3">
      <div className="row">
        <div className="col-md-6 px-0">
        
          <input
            className="inputs my-3 Lite"
            type="text"
            placeholder={email}
            value={Email}
            onChange={(e) => {
              EmailValid(e);
            }}
            style={{
              color: `${color}`,
              borderRadius: "0px",
              borderBottom: `1px solid ${Color}`,
            }}
          />  <input
          className="inputs my-3 Lite"
          type="text"
          placeholder={phone}
          value={Phone}
          onChange={(e) => {
            PhoneValid(e);
          }}
          style={{
            color: `${color}`,
            borderRadius: "0px",
            borderBottom: `1px solid ${Color}`,
          }}
        />
        </div>
      </div>
    </div>
  );
}
