import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
//import { useDispatch } from "react-redux";
//import { SetMode, SetIsOpen } from "../store/UserReducer";
export function useOpenCheck() {
  const URL = useSelector((e) => e.UserReducer.url);
  const [IsOpen, setIsOpen] = useState(null);
  axios
    .get(`${URL}/get_open_close.php`, {
      "Content-Type": "application/json",
    })
    .then((response) => {
      // console.log(response.data);

      setIsOpen(response.data.is_open.is_open_qr);

      // SetMode(response.data.is_open.auto_open_close, dispatch)
      //   SetIsOpen(response.data.is_open.is_open, dispatch)
    })

    .catch((err) => {
      //   console.log(err.response);
      setIsOpen(null);
    });

  return IsOpen;
}
