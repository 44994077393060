import React, { useState, useEffect } from "react";
import IconsMineDisplay from "./IconsMineDisplay";
import { useSelector, useDispatch } from "react-redux";
import Category from "./Category";
import CheckBoxComponent from "./CheckBoxComponent";
import "../CSS/ItemWithOptions.css";
import "../CSS/Footer.css";
import Exit from "../Media/exit.svg";
import round from "../Functions/round";
import Footer from "./Footer";
import { AddOrderPosition, AddToAmmount } from "../store/OrderReducer";
import { SetAlert } from "../store/UserReducer";
import { Spring } from "react-spring/renderprops";
import TakeInfo from "./TakeInfo";
import SubmitDisplay from "./SubmitDisplay";
import { useOpenCheck } from "../Functions/useOpenCheck";
export default function MineDisplay(props) {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [Accept_regulations_error, setAccept_regulations_error] = useState("");
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Correct_email_error, setCorrect_email_error] = useState("");
  const [Correct_meal_error, setCorrect_meal_error] = useState("");
  const [Delivery_city, setDelivery_city] = useState("");
  const [Correct_phone_error, setCorrect_phone_error] = useState("");
  const [Correct_date_error, setCorrect_date_error] = useState("");
  const [Meal_additions_header, setMeal_additions_header] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [
    Confirm_meal_without_addition,
    setConfirm_meal_without_addition,
  ] = useState("");
  const [Confirm_meal_with_addition, setConfirm_meal_with_addition] = useState(
    ""
  );
  const [Added_to_order_communicate, setAdded_to_order_communicate] = useState(
    ""
  );
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }
 
    if (UserInfo.constant_texts !== undefined) {
      setAccept_regulations_error(
        UserInfo.constant_texts.accept_regulations_error
      );
      setCorrect_email_error(UserInfo.constant_texts.correct_email_error);
      setCorrect_meal_error(UserInfo.constant_texts.correct_meal_error);
      setDelivery_city(UserInfo.constant_texts.delivery_city);
      setCorrect_phone_error(UserInfo.constant_texts.correct_phone_error);
      setCorrect_date_error(UserInfo.constant_texts.correct_data_error);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setConfirm_meal_without_addition(
        UserInfo.constant_texts.confirm_meal_without_addition
      );
      setConfirm_meal_with_addition(
        UserInfo.constant_texts.confirm_meal_with_addition
      );
      setAdded_to_order_communicate(
        UserInfo.constant_texts.added_to_order_communicate
      );
    }
    if (UserInfo.editable_texts !== undefined) {
      setMeal_additions_header(UserInfo.editable_texts.meal_additions_header);
    }
  }, [UserInfo]);
  const [Height, setHeight] = useState(
    window.scrollY >= 0 && window.scrollY <= 150 && window.innerWidth >= 1400
      ? 200
      : -0.75 * window.scrollY + 200
  );

  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (
        window.scrollY >= 0 &&
        window.scrollY <= 150 &&
        window.innerWidth >= 1400
      ) {
        setHeight(-0.75 * window.scrollY + 200);
      } else {
        setHeight(91.55);
      }
    });
  });
  const Categorier = useSelector((a) => a.MenuReducer.menu);
  const [Addition, setAddition] = useState([]);
  const [Display, setDisplay] = useState("Mine");
  const [DisplayConfirm, setDisplayConfirm] = useState("hidden");
  const [AdditionalList, setAdditionalList] = useState({});
  const [Meal, setMeal] = useState([]);
  const [LocalAmount, setLocalAmount] = useState(0);
  const [Button_text, setButton_text] = useState(Confirm_meal_without_addition);
  useEffect(() => {
    if (Object.keys(AdditionalList).length > 0) {
      setButton_text(Confirm_meal_with_addition);
    } else if (Object.keys(AdditionalList).length === 0) {
      setButton_text(Confirm_meal_without_addition);
    }
  });
  const addCheckedElement = (element) => {
    let list = AdditionalList;
    setAdditionalList();
    list[element.id] = {
      id: element.id,
      name: element.name,
      price: element.price,
    };
    setAdditionalList(list);
    setLocalAmount(LocalAmount + element.price);
    // console.log("lista dodatków");
    //  console.log(list);
    //  console.log(AdditionalList);
  };
  const removeCheckedElement = (element) => {
    let list = AdditionalList;
    delete list[element.id];
    setAdditionalList(list);
    setLocalAmount(LocalAmount - element.price);
    // console.log("lista dodatków");
    // console.log(list);
  };

  const showAdditions = (order, addition) => {
    setDisplayConfirm("hidden");
    setMeal(order);
    //console.log(order);
    // console.log(addition);
    setLocalAmount(order.price);
    setAddition(addition);
    setDisplay("Additions");
  };
  const cancel = () => {
    setDisplay("Mine");
    setAdditionalList({});
    setLocalAmount(0);
    setAddition([]);
  };
  const dispatch = useDispatch();
  const addItemToOrder = () => {
    let OrderItem = [Meal, AdditionalList, LocalAmount];
    if (
      AddOrderPosition(OrderItem, dispatch) &&
      AddToAmmount(OrderItem[2], dispatch)
    ) {
      // console.log("dodany item");
      // console.log(OrderItem);
      setDisplayConfirm("visible");
      setTimeout(function () {
        cancel();
      }, 1000);
    }
  };

  const addItemToOrderNoAdditions = (order, addition) => {
    let OrderItem = [order, addition, order.price];
    if (
      AddOrderPosition(OrderItem, dispatch) &&
      AddToAmmount(OrderItem[2], dispatch)
    ) {
      return true;
    }
  };
  //-------------------------Tworzenie ORDERU---------------------------------
  const dataCrator = () => {
    let now = new Date();

    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
  };
  const timeCrator = () => {
    let now = new Date();
    let s = `${now.getMinutes()}`;
    console.log(s);
    if (s.length == 1) {
      s = "0" + `${now.getMinutes()}`;
    }
    return `${now.getHours()}:${s}`;
  };
  const random = () => {
    let cod =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

    //console.log("Wyrandowany kod:" + cod);
    return cod;
  };
  const optionsCreator = (add) => {
    let additions = [];
    Object.keys(add).map((element) => {
      additions.push({
        addition: add[element].id,
      });
    });
    // console.log(additions);
    return additions;
  };
  const mealCreator = () => {
    // console.log(Order);
    let meals = [];
    Object.keys(Order).map((element) => {
      meals.push({
        meal_option: Order[element].idOption,
        quantity: 1,
        additions: optionsCreator(Order[element].additions),
      });
    });
    // console.log(meals);
    return meals;
  };

  const [CHK, setCHK] = useState(null);
  const [Description, setDescription] = useState(null);
  const Agree = useSelector((a) => a.OrderReducer.agree);
  const Agree2 = useSelector((a) => a.OrderReducer.agree2);
  const Order = useSelector((a) => a.OrderReducer.order);
  const City = useSelector((a) => a.OrderReducer.city);
  const Street = useSelector((a) => a.OrderReducer.street);
  const BuildNr = useSelector((a) => a.OrderReducer.buildNr);
  const Name = useSelector((a) => a.OrderReducer.name);
  const Surname = useSelector((a) => a.OrderReducer.surname);
  const Phone = useSelector((a) => a.OrderReducer.phone);
  const Email = useSelector((a) => a.OrderReducer.email);
  const Ammount = useSelector((a) => a.OrderReducer.ammount);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);
  const DeliveryAmmount = useSelector((a) => a.OrderReducer.deliveryAmmount);
  const Code = useSelector((a) => a.OrderReducer.code);
  const Coment = useSelector((a) => a.OrderReducer.coment);
  const Off = useSelector((a) => a.OrderReducer.off);
  const [Json, setJson] = useState(null);
  const IsOpen = useOpenCheck();
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const createOrder = async () => {
    if (
      Object.keys(Order).length !== 0 &&
      City !== null &&
      IsOpen === true &&
      Street.length >= 0 &&
      BuildNr.length >= 0 &&
      Name.length >= 0 &&
      Surname.length >= 0 &&
     // Phone.length === 9 &&
     // Email.length >= 0 &&
      Agree === true  
      //Phone.length === 9 &&
  
    ) {
  
        let price =
          Ammount - Off > FreeAbove
            ? round(Ammount - Off)
            : round(Ammount + City.delivery_price - Off);
        let control = await random();
        let description =" mail:" + Email;
        setDescription(description);
     
        setJson({
          delivery_city: City.id,
          client_name: Name + " " + Surname,
          delivery_address: Street + " " + BuildNr,
          phone_number: Phone,
          email: Email,
          ordered_date: await dataCrator(),
          ordered_time: await timeCrator(),
          to_pay: price.toFixed(2),
          delivery_price: Ammount - Off > FreeAbove ? 0 : DeliveryAmmount,
          comment: Coment,
          preparation_time: "",
          promotion_code: Code,
          payment_control: control,
          payment_type: "",
          payment_status: "",
          progress: "",
          meal_options: await mealCreator(),
          marketing_permit: Agree2,
        });
        setDisplay("Submit");
        SetAlert(null, dispatch);
        window.scrollTo(0, 0);
     
    } else {
      if (Object.keys(Order).length === 0) {
        SetAlert(Correct_meal_error, dispatch);
      } else if (City === null) {
        SetAlert(Delivery_city, dispatch);
      }
       // else if (Phone.length !== 9) {
       // SetAlert(Correct_phone_error, dispatch);
       // }
       else if (Agree !== true) {
        SetAlert("Zaakceptuj regulamin", dispatch);
      } 
      //else if (Email === "") {
     //   SetAlert(Correct_email_error, dispatch);
    //  } 
      else {
        SetAlert(Correct_date_error, dispatch);
      }
    }
  };
  if (Display === "Additions") {
    return (
      <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
        {(props) => (
          <div style={props}>
            <div
              className="row px-md-5 px-3 pt-md-4 pt-0"
              style={{ paddingBottom: "150px" }}
            >
              <div className="col-10 px-0 my-3 my-md-0">
                <p className="h2 Bold upper my-sm-5 footer-font2">
                  {Meal_additions_header}
                </p>
              </div>
              <div className="col-2 px-0 my-3 my-md-0">
                <img
                  onClick={cancel}
                  src={Exit}
                  alt="Anuluj"
                  id="exit"
                  className="d-block mx-auto my-sm-5"
                />
              </div>
              {Addition.map((element) => (
                <>
                  <div className="col-12 px-0 mt-md-3  mb-1">
                    <p className="h3 Bold upper f-size-title mb-0">
                      {element.name}
                    </p>
                  </div>

                  <div className="col-12 px-0 mb-4 mb-md-2 mb-2 f-size-disc">
                    <CheckBoxComponent
                      options={element.additions}
                      add={addCheckedElement}
                      remove={removeCheckedElement}
                      color={Additions_stop_color}
                      order_currency={Order_currency}
                    />
                  </div>
                </>
              ))}
            </div>
            <div
              className="container-fluid chosenMealArea px-md-5 pt-0"
              style={{ display: Display }}
            >
              <div
                className="container  px-0 py-0 "
                style={{
                  background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
                  color: `${Additions_font_color}`,
                }}
              >
                <div className="row ">
                  <div className="col-12 px-md-5 px-3 ">
                    <div className="row py-lg-3 py-md-0">
                      <div className="col-lg-7 mb-md-0 mb-2 px-0 col-sm-10 col-9 d-flex align-items-center pt-2 pt-md-0 positon-relative">
                        <span>
                          <p className="f-size-title Bold upper mb-0">
                            {Meal.name}{" "}
                            {Meal.optName === "" ? "" : "- " + Meal.optName}
                          </p>
                          <p className="f-size-disc mb-2">
                            {Object.keys(AdditionalList).map((key) => (
                              <span className="mr-1">
                                {AdditionalList[key].name},
                              </span>
                            ))}
                          </p>
                        </span>
                      </div>
                      <div className="col-sm-2 col-3 mb-md-0 mb-2 px-0 text-center d-flex align-items-center justify-content-end">
                        {" "}
                        <p className="upper Bold mb-0 f-size-title">
                          {round(LocalAmount).toFixed(2)} {Order_currency}
                        </p>
                      </div>
                      <div className="col-lg-3  px-0 text-center d-flex align-items justify-content-end py-md-3 pb-2 ">
                        <button
                          className="btn add-regular-additions Bold  py-2 upper"
                          onClick={() => {
                            addItemToOrder();
                          }}
                          style={{
                            background: `white`,
                            color: `${Additions_stop_color}`,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {Button_text}
                        </button>
                      </div>
                    </div>
                    <div
                      className={
                        "confirmation3 text-center    d-flex justify-content-center align-items-center " +
                        DisplayConfirm
                      }
                    >
                      <p className="mb-0 Bold text-white h3 h3-custom">
                        {" "}
                        {Added_to_order_communicate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  } else if (Display === "Mine") {
    return (
      <>
        {Categorier.map((element) => {
          if (element.is_active) {
            return (
              <Category
                key={element.id}
                category={element}
                additions={showAdditions}
                addNoAdditions={addItemToOrderNoAdditions}
              />
            );
          }
        })}
        <IconsMineDisplay />
        <TakeInfo func={createOrder} />
        <Footer />
      </>
    );
  } else if (Display === "Submit") {
    return (
      <SubmitDisplay
      name={Name}
      surname={Surname}
      json={Json}   
      description={Description}
      additions_font_color={Additions_font_color}
      additions_start_color={Additions_start_color}
      additions_stop_color={Additions_stop_color}
      height={props.test}
      set={setDisplay}
      />
    );
  }
}
