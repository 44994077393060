import React, { useEffect, useState } from "react";
import Header from "./Header";
import Display from "./Display";
import { useSelector } from "react-redux";

import "../CSS/Mine.css";
export default function Mine({ match }) {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [Background, setBackground] = useState("");
  useEffect(() => {
    if (UserInfo.img !== undefined) {
      setBackground(UserInfo.img.background);
    }
  }, [UserInfo]);
  const [Height, setHeight] = useState(
    window.innerWidth >= 1400 ? -0.75 * window.scrollY + 150 : 70
  );
  const [Opacity, setOpacity] = useState(
    window.scrollY >= 0 && window.scrollY <= 100 && window.innerWidth >= 1400
      ? 1
      : (-1 / 150) * window.scrollY + 1
  );
  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (
        window.scrollY >= 0 &&
        window.scrollY <= 100 &&
        window.innerWidth >= 1400
      ) {
        setHeight(-0.75 * window.scrollY + 150);
        setOpacity((-1 / 150) * window.scrollY + 1);
      } else {
        setHeight(70);
        setOpacity(0);
      }
    });
  });

  return (
    <div
      className="container-fluid mine-background "
      style={{ backgroundImage: `url(${Background})` }}
    >
      <Header height={Height} opacity={Opacity} />
      <Display height={Height} />
    </div>
  );
}
