import React, { useEffect, useState } from "react";
import hexatorgb from "./Functions/hexatorgb";
export default function Style({ color }) {
  const [Color, setColor] = useState("");
  useEffect(() => {
    setColor(hexatorgb(color, 80));
  }, [color]);
  return (
    <style>
      {`
        ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Color};
  
        }
        
        :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${Color};
        }
        
        ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${Color};
        }
        `}
    </style>
  );
}
