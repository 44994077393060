export const ADD_ORDER_POSITION = "ADD_ORDER_POSITION";
export const ADD_TO_AMMOUNT = "ADD_TO_AMMOUNT";
export const REMOVE_FROM_AMMOUNT = "REMOVE_FROM_AMMOUN";
export const REMOVE_ORDER_POSITION = "REMOVE_ORDER_POSITION";
export const SET_DELIVERY = "SET_DELIVERY";
export const SET_CITY = "SET_CITY";
export const SET_STREET = "SET_STREET";
export const SET_BUILDNR = "SET_BUILDNR";
export const SET_NAME = "SET_NAME";
export const SET_SURNAME = "SET_SURNAME";
export const SET_PHONE = "SET_PHONE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_CODE = "SET_CODE";
export const SET_COMENT = "SET_COMENT";
export const SET_DELIVERYAMMOUNT = "SET_DELIVERYAMMOUNT";
export const SET_FREEABOVE = "SET_FREEABOVE";
export const SET_OFF = "SET_OFF";
export const SET_AGREE = "SET_AGREE";
export const SET_AGREE2 = "SET_AGREE2";
const initialState = {
  order: {},
  delivery: {},
  ammount: 0,
  off: 0,
  deliveryAmmount: 0,
  freeAbove: null,
  city: null,
  street: "-",
  buildNr: "-",
  name: "-",
  surname: "-",
  phone: "",
  email: "",
  code: "",
  coment: "",
  agree: false,
  agree2: false,
};
String.prototype.escapeDiacritics = function () {
  return this.replace(/ą/g, "a")
    .replace(/Ą/g, "A")
    .replace(/ć/g, "c")
    .replace(/Ć/g, "C")
    .replace(/ę/g, "e")
    .replace(/Ę/g, "E")
    .replace(/ł/g, "l")
    .replace(/Ł/g, "L")
    .replace(/ń/g, "n")
    .replace(/Ń/g, "N")
    .replace(/ó/g, "o")
    .replace(/Ó/g, "O")
    .replace(/ś/g, "s")
    .replace(/Ś/g, "S")
    .replace(/ż/g, "z")
    .replace(/Ż/g, "Z")
    .replace(/ź/g, "z")
    .replace(/Ź/g, "Z");
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ORDER_POSITION": {
      let newOrder = state.order;
      // console.log("");
      // console.log(action.payload);
      var d = new Date();
      var n = d.getTime();

      newOrder[n] = {
        name: action.payload[0].name,
        idMeal: action.payload[0].idMeal,
        mealPrise: action.payload[0].price,
        optName: action.payload[0].optName,
        idOption: action.payload[0].idOption,
        additions: action.payload[1],
        localAmmount: action.payload[2],
      };
      //console.log(newOrder);
      return {
        ...state,
        order: newOrder,
      };
    }
    case "REMOVE_ORDER_POSITION": {
      let newOrder = state.order;
      delete newOrder[action.payload];
      // console.log(newOrder);
      return {
        ...state,
        order: newOrder,
      };
    }
    case "ADD_TO_AMMOUNT": {
      let newAmmount = state.ammount + action.payload;
      // console.log(newAmmount);
      return {
        ...state,
        ammount: newAmmount,
      };
    }
    case "SET_DELIVERYAMMOUNT": {
      return {
        ...state,
        deliveryAmmount: action.payload,
      };
    }
    case "SET_FREEABOVE": {
      return {
        ...state,
        freeAbove: action.payload,
      };
    }
    case "REMOVE_FROM_AMMOUN": {
      let newAmmount = state.ammount - action.payload;
      if (newAmmount < 0) {
        newAmmount = 0;
      }
      return {
        ...state,
        ammount: newAmmount,
      };
    }
    case "SET_DELIVERY": {
      return {
        ...state,
        delivery: action.payload,
      };
    }
    case "SET_OFF": {
      return {
        ...state,
        off: action.payload,
      };
    }
    //Inputs validation
    case "SET_CITY": {
      let newCity = action.payload;
      // console.log(newCity);
      return {
        ...state,
        city: newCity,
      };
    }
    case "SET_STREET": {
      let newStreet = action.payload;
      // console.log(newStreet);
      return {
        ...state,
        street: newStreet,
      };
    }
    case "SET_BUILDNR": {
      let newBulidNr = action.payload;
      // console.log(newBulidNr);
      return {
        ...state,
        buildNr: newBulidNr,
      };
    }
    case "SET_NAME": {
      let label = new String(action.payload);
      let newName = label.escapeDiacritics();
      //console.log(newName);
      return {
        ...state,
        name: newName,
      };
    }
    case "SET_SURNAME": {
      let label = new String(action.payload);
      let newSurname = label.escapeDiacritics();
      // console.log(newSurname);
      return {
        ...state,
        surname: newSurname,
      };
    }
    case "SET_PHONE": {
      let newPhone = action.payload.replace(/\D/, "");
      if (newPhone.length <= 9) {
        return {
          ...state,
          phone: newPhone,
        };
      } else {
        return {
          ...state,
        };
      }

      // console.log(newPhone);
    }
    case "SET_EMAIL": {
      let label = new String(action.payload);
      let newEmail = label.escapeDiacritics();

      // console.log(newEmail);
      return {
        ...state,
        email: newEmail,
      };
    }
    case "SET_CODE": {
      let newCode = action.payload;
      // console.log(newCode);
      return {
        ...state,
        code: newCode,
      };
    }
    case "SET_COMENT": {
      let newComent = action.payload;
      //  console.log(newComent);
      return {
        ...state,
        coment: newComent,
      };
    }
    case "SET_AGREE": {
      let newAgree = action.payload;
      // console.log(newAgree);
      return {
        ...state,
        agree: newAgree,
      };
    }
    case "SET_AGREE2": {
      let newAgree = action.payload;
      //console.log(newAgree);
      return {
        ...state,
        agree2: newAgree,
      };
    }
    default: {
      //  console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};

export const AddOrderPosition = (order, dispatch) => {
  if (dispatch({ type: ADD_ORDER_POSITION, payload: order })) {
    return true;
  }
};
export const RemoveOrderPosition = (order, dispatch) => {
  if (dispatch({ type: REMOVE_ORDER_POSITION, payload: order })) {
    return true;
  }
};
export const AddToAmmount = (ammount, dispatch) => {
  if (dispatch({ type: ADD_TO_AMMOUNT, payload: ammount })) {
    return true;
  }
};
export const RemoveFromAmmount = (ammount, dispatch) => {
  if (dispatch({ type: REMOVE_FROM_AMMOUNT, payload: ammount })) {
    return true;
  }
};
export const SetDeliveryAmmount = (deliveryAmmount, dispatch) => {
  if (dispatch({ type: SET_DELIVERYAMMOUNT, payload: deliveryAmmount })) {
    return true;
  }
};
export const SetFreeAbove = (freeAbove, dispatch) => {
  if (dispatch({ type: SET_FREEABOVE, payload: freeAbove })) {
    return true;
  }
};
export const SetOff = (off, dispatch) => {
  if (dispatch({ type: SET_OFF, payload: off })) {
    return true;
  }
};
//Inputs validation
export const SetCity = (city, dispatch) => {
  if (dispatch({ type: SET_CITY, payload: city })) {
    return true;
  }
};
export const setDelivery = (delivery, dispatch) => {
  if (dispatch({ type: SET_DELIVERY, payload: delivery })) {
    return true;
  }
};
export const SetStreet = (street, dispatch) => {
  if (dispatch({ type: SET_STREET, payload: street })) {
    return true;
  }
};
export const SetBuildNr = (buildNr, dispatch) => {
  if (dispatch({ type: SET_BUILDNR, payload: buildNr })) {
    return true;
  }
};
export const SetName = (name, dispatch) => {
  if (dispatch({ type: SET_NAME, payload: name })) {
    return true;
  }
};
export const SetSurame = (surname, dispatch) => {
  if (dispatch({ type: SET_SURNAME, payload: surname })) {
    return true;
  }
};
export const SetPhone = (phone, dispatch) => {
  if (dispatch({ type: SET_PHONE, payload: phone })) {
    return true;
  }
};
export const SetEmail = (email, dispatch) => {
  if (dispatch({ type: SET_EMAIL, payload: email })) {
    return true;
  }
};
export const SetCode = (code, dispatch) => {
  if (dispatch({ type: SET_CODE, payload: code })) {
    return true;
  }
};
export const SetComent = (coment, dispatch) => {
  if (dispatch({ type: SET_COMENT, payload: coment })) {
    return true;
  }
};
export const SetAgree = (agree, dispatch) => {
  if (dispatch({ type: SET_AGREE, payload: agree })) {
    return true;
  }
};
export const SetAgree2 = (agree2, dispatch) => {
  if (dispatch({ type: SET_AGREE2, payload: agree2 })) {
    return true;
  }
};
