import React from "react";

export default function AdditionsList({ additionList }) {
  return (
    <p className="mb-0 Lite f-size">
      {Object.keys(additionList).map((key) => (
        <> {additionList[key].name + ","} </>
      ))}
    </p>
  );
}
