export const SET_MENU_CATEGORY = "SET_MENU_CATEGORY";

const initialState = {
  menu: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_MENU_CATEGORY": {
      const newMenu = action.payload;
      // console.log(`git ${action.type}`);
      return {
        ...state,
        menu: newMenu,
      };
    }
    default: {
      // console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};

export const SetMenuCategory = (menu, dispatch) => {
  if (dispatch({ type: SET_MENU_CATEGORY, payload: menu })) {
    return true;
  }
};
