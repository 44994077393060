import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOpenCheck } from "../Functions/useOpenCheck";
import { SetAgree, SetAgree2 } from "../store/OrderReducer";
import OrderList from "./OrderList";
import ClientInfo from "./ClientInfo";
import "../CSS/Display.css";
export default function TakeInfo({ func }) {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const URL = useSelector((e) => e.UserReducer.url);
  const IsOpen = useOpenCheck();
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  //inputs
  const [Delivery_city, setDelivery_city] = useState("");
  const [Delivery_street, setDelivery_stree] = useState("");
  const [Delivery_building_number, setDelivery_building_number] = useState("");
  const [Delivery_client_name, setDelivery_client_name] = useState("");
  const [Delivery_client_surname, setDelivery_client_surname] = useState("");
  const [Delivery_client_phone, setDelivery_client_phone] = useState("");
  const [Delivery_client_email, setDelivery_client_email] = useState("");
  const [Confirm_order_button, setConfirm_order_button] = useState("");
  const [Order_summary_check_1, setOrder_summary_check_1] = useState("");
  const [Site_regulations_link, setSite_regulations_link] = useState("");
  const [Site_regulations, setSite_regulations] = useState("");
  const [Order_summary_check_2, setOrder_summary_check_2] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }
    if (UserInfo.constant_texts !== undefined) {
      setDelivery_city(UserInfo.constant_texts.delivery_city);
      setDelivery_stree(UserInfo.constant_texts.delivery_street);
      setDelivery_building_number(
        UserInfo.constant_texts.delivery_building_number
      );
      setDelivery_client_name(
        UserInfo.constant_texts.delivery_client_first_name
      );
      setDelivery_client_surname(
        UserInfo.constant_texts.delivery_client_surname
      );
      setDelivery_client_phone(UserInfo.constant_texts.delivery_client_phone);
      setDelivery_client_email(UserInfo.constant_texts.delivery_client_email);
      setConfirm_order_button(UserInfo.constant_texts.confirm_order_button);
      setOrder_summary_check_1(UserInfo.constant_texts.order_summary_check_1);
      setSite_regulations_link(UserInfo.constant_texts.site_regulations_link);
      setSite_regulations(UserInfo.constant_texts.site_regulations);
      setOrder_summary_check_2(UserInfo.constant_texts.order_summary_check_2);
    }
  }, [UserInfo]);
  const dispatch = useDispatch();
  const accept = (e) => {
    if (e.target.checked) {
      SetAgree(true, dispatch);
    } else {
      SetAgree(false, dispatch);
    }
  };
  const accept2 = (e) => {
    if (e.target.checked) {
      SetAgree2(true, dispatch);
    } else {
      SetAgree2(false, dispatch);
    }
  };
  return (
    <div
      className="row py-5 px-md-5 px-0 cart-hidden"
      style={{
        background: `url(${URL}/img/restaurant/background_basket.svg),transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat  padding-box`,
        color: `${Additions_font_color}`,
      }}
    >
      <OrderList color={Additions_font_color} />
      <ClientInfo
        background={`transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`}
        color={Additions_font_color}
        email={Delivery_client_email}
        city={Delivery_city}
        street={Delivery_street}
        bulidingNr={Delivery_building_number}
        name={Delivery_client_name}
        surname={Delivery_client_surname}
        phone={Delivery_client_phone}
      />
      <div className="col-12 px-md-5 px-4 ">
        <div
          class="form-check form-check-inline mr-md-5 mr-3 mt-2 Lite position-relative cont2"
          style={{
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <input
            c
            class="form-check-input d-none"
            type="checkbox"
            id="accept"
            onChange={(e) => {
              accept(e);
            }}
            style={{ cursor: "pointer" }}
          />

          <label
            class="form-check-label res"
            for="accept"
            style={{
              border: `2px solid ${Additions_font_color}`,
              cursor: "pointer",
            }}
          ></label>

          <label
            class="form-check-label res2"
            for="accept"
            style={{
              cursor: "pointer",

              paddingLeft: "30px",
            }}
          >
            {Order_summary_check_1}
            <a
              href={Site_regulations_link}
              style={{ color: `${Additions_font_color}` }}
            >
              {" "}
              <b>
                <u>{Site_regulations}</u>
              </b>
            </a>
          </label>
          <style>
            {`
        .cont2 input:checked + .res {
          border: 2px solid #00000;
        }
        .cont2 input:checked + .res:after {
          content: "";
          position: absolute;
          height: 7px;
          width: 10px;
          border-bottom: 2px solid #000000;
          border-left:  2px solid #000000;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        
        `}
          </style>
        </div>
      </div>
      <div
        className="col-12 px-md-5 px-4 mt-2"
        style={{
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        <div
          class="form-check form-check-inline mr-md-5 mr-3 mt-2 Lite position-relative cont2"
          style={{
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <input
            c
            class="form-check-input d-none"
            type="checkbox"
            id="accept2"
            onChange={(e) => {
              accept2(e);
            }}
            style={{ cursor: "pointer" }}
          />

          <label
            class="form-check-label res"
            for="accept2"
            style={{
              border: `2px solid ${Additions_font_color}`,
              cursor: "pointer",
            }}
          ></label>

          <label
            class="form-check-label res2"
            for="accept2"
            style={{
              cursor: "pointer",

              paddingLeft: "30px",
            }}
          >
            {Order_summary_check_2}
          </label>
          <style>
            {`
        .cont2 input:checked + .res {
          border: 2px solid ${Additions_font_color};
        }
        .cont2 input:checked + .res:after {
          content: "";
          position: absolute;
          height: 7px;
          width: 10px;
          border-bottom: 2px solid ${Additions_font_color};
          border-left:  2px solid ${Additions_font_color};
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        
        `}
          </style>
        </div>
      </div>

      <div className="col-sm-6 px-md-5 px-4 my-sm-4 my-3">
        <button
          className="btn add-regular Bold  py-2 upper"
          style={{
            background: `${Additions_font_color}`,
            color: `${Additions_stop_color}`,
          }}
          onClick={() => {
            func();
          }}
          disabled={!IsOpen}
        >
          {Confirm_order_button}
        </button>
      </div>
    </div>
  );
}
