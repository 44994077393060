import React from "react";
import "../CSS/Loading.css";
export default function Loading() {
  return (
    <div className="bodyLoad">
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  );
}
